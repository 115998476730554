import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalValuesService } from '../../global-values/global-values.service';
import { MasterListRequest } from '../../../models/template/many-to-one-relation/many-to-one-relation';
import { SessionStorageService } from 'angular-web-storage';
@Injectable({
  providedIn: 'root',
})
export class ManyToManyRelationService {
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  /**Variable which holds getManyToOneSearchData API url */
  mt1SearchDataUrl: string = this.globalValue.creatorBaseURL + 'master/list';
  /**Function to handle getSearchData*/
  getManyToManySearchData(params: any): Observable<MasterListRequest> {
    // return this.http.get<MasterListRequest>('/assets/json/popup_sample.json');
    return this.http.get<MasterListRequest>(this.mt1SearchDataUrl, {
      params: params,
    });
  }
  /**Variable which holds getManyToOneSearchData API url */
  mt1SearchDataContentTypeUrl: string = this.globalValue.creatorBaseURL + 'form/content/list';
  /**Function to handle getSearchData*/
  getManyToManySearchDataContentType(params: any){
    
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({ 
      token: sessionData.token
    }); 
    // return this.http.get<MasterListRequest>('/assets/json/popup_sample.json');
    return this.http.get(this.mt1SearchDataContentTypeUrl, {
      params: params,
      headers: headers
    });
  }
  /**Variable which holds getManyToOneSearchData API url */
  mtmSearchDetailUrl: string = this.globalValue.creatorBaseURL + 'master/list';
  /**Function to handle getSearchData*/
  getManyToManySearchDetail(params: any){
    return this.http.get<MasterListRequest>(this.mtmSearchDetailUrl, {
      params: params,
    });
  }
  /**Variable which holds getManyToOneSearchData API url */
  mtmSearchDetailContentUrl: string = this.globalValue.creatorBaseURL + 'form/content/list';
  /**Function to handle getSearchData*/
  getManyToManySearchContentDetail(params: any){
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({ 
      token: sessionData.token
    }); 
    return this.http.get(this.mtmSearchDetailContentUrl,{
      params: params,
      headers: headers
    });
  }

}
