import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalValuesService } from '../global-values/global-values.service';
import { SessionStorageService } from 'angular-web-storage';
import { ContentSaveResponse } from '../../models/template/content-save/content-save.response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SaveTemplateService {
  /**
   * Constructor which holds httpclient and service
   * @param http HTTPClient imports
   * @param {GlobalValuesService}
   */
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  /**Variable which holds saveTemplate API url */
  saveTemplateUrl: string = this.globalValue.creatorBaseURL + 'content/save';
  /**Function to handle getTemplate*/
  saveTemplate(template: any){
    // console.log(template)
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({
      token: sessionData.token
    });
    return this.http.post(this.saveTemplateUrl, template,{ headers });
  }

  /**Variable which holds masterDetail API url */
  masterDetailUrl: string = this.globalValue.creatorBaseURL + 'master/detail?masterId=';
  /**Function to handle Get master Detail*/
  masterDetail(id : number) {
    return this.http.get(this.masterDetailUrl+id);
  }

  contentListUrl: string = this.globalValue.creatorBaseURL + 'content/list';
  /**Function to handle getManyToOneSearchData
   * @param params
  */
  contentList(params : any): Observable<any> {
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({ 
      token: sessionData.token
    }); 
    return this.http.get<any>(this.contentListUrl, {
      params: params,
      headers: headers
    });
  }

}
