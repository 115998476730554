import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { creatorText } from 'src/app/content-creator/creator.messages';
import { SessionStorageService } from 'angular-web-storage';
import { SwitchroleService } from 'src/app/content-creator/services/header/switchrole.service';
import { MatTableDataSource } from '@angular/material/table';
import { TemplateService } from 'src/app/content-creator/services/templates/template.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ManyToOneRelationService } from 'src/app/content-creator/services/templates/many-to-one-relation/many-to-one-relation.service';
import { ManyToManyRelationService } from 'src/app/content-creator/services/templates/many-to-many-relation/many-to-many-relation.service';
import { UitiltyService } from 'src/app/content-creator/services/utilties/utilities.service';
import { DocParserRequest } from 'src/app/content-creator/models/utilities/docParser.request';
import { WebScrapperRequest } from 'src/app/content-creator/models/utilities/webScrapper.request';
import { SaveTemplateService } from 'src/app/content-creator/services/templates/save-template.service';
import { LoginService } from 'src/app/content-creator/services/login/login.service';
import { SwitchRoleRequest } from 'src/app/content-creator/models/Login/switch-role/switch-role-request';
import { FormGroup } from '@angular/forms';
import { SuccessErrorPopupComponent } from 'src/app/content-curator/components/common/success-error-popup/success-error-popup.component';
import { ContentTransLanguageRequest } from 'src/app/content-creator/models/Translation/content-translanguage-request';
import { TranslanguageService } from 'src/app/content-creator/services/translate/translanguage.service';
import { HeaderService } from 'src/app/content-creator/services/header/header.service';
import { SpinnerService } from 'src/app/content-creator/services/spinner/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { MenuService } from 'src/app/content-creator/services/menu/menu.service';

export class SearchData {
  constructor(
    public Id: string,
    public Path: string,
    public Status: string,
    public CreationDate: string,
    public ModificationDate: string,
    public CommonName: string
  ) {}
}

export class SearchDataOnlyone {
  constructor(
    public Id: string,
    public AliasName: string,
    public Name: string,
    public FileName: string,
    public dialog: MatDialog,
  ) {}
}

/**Component which holds Dialog box functionality*/
@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss'],
})
export class DialogBoxComponent implements OnInit {
  /**Variable that holds the static Role Switcher text */
  roleSwitcherText = creatorText.roleSwitcherText;
  /**Variable that holds the static Role Swicther instruction text */
  roleSelection = creatorText.roleSelection;
  /**Variable that holds the static Proceed text */
  proceedText = creatorText.proceedText;
  /**Variable that holds the static Cancel text */
  cancelText = creatorText.cancelText;
  /**Variable that holds the static Delete Title text */
  deleteFormText = creatorText.deleteFormText;
  /**Variable that holds the static Delete Confirmation text */
  deleteConfirmation = creatorText.deleteConfirmation;
  /**Variable that holds the static Success text */
  successText = creatorText.successText;
  /**Variable that holds the static Success Message text */
  successMessage = creatorText.successMessage;
  /**Variable that holds the static validation text */
  validationFailedText = creatorText.validationFailedText;
  /**Variable that holds the static Error Message text */
  errorMessage = creatorText.errorMessage;
  /**Variable that holds the static add text */
  addText = creatorText.addText;
  /**Variable that holds the static Success Message text */
  userRole: string = '';
  /*Variable which holds static create content text */;
  createContentText = creatorText.createContentText;
  /*Variable that holds static initiate translation text */;
  initiateTranslationText=creatorText.initiateTranslationText;
  /*Variable that holds static translation languages text */;
  translationLanguages=creatorText.translationLanguages;
  /**Variable that holds the static country text */
  countryText = creatorText.countryText;
  /**Variable that holds the static crop text */
  cropText = creatorText.cropText;
  /**Variable that holds the static formFillCaution text */
  formFillCaution = creatorText.formFillCaution;
  /**Variable that holds the static process text */
  process = creatorText.process;
  /**Variable that holds the static noContentsFound text */
  noContentsFoundText = creatorText.noContentsFoundText;
  /**Variable that holds the static processingText text */
  processingText = creatorText.processingText;
  /**Variable that holds the static notesText text */
  notesText = creatorText.notesText;
  /**Variable that holds the static commentBox text */
  commentBoxText = creatorText.commentBoxText;
  /**Variable that holds the static text value */
  textText = creatorText.text;
  /**Variable that holds the static path text */
  pathText = creatorText.pathText;
  /**Variable that holds the static title text */
  titleText = creatorText.title;
  /**Variable that holds the static cropStage text */
  cropStageText = creatorText.cropStageText;
  /**Variable that holds rename text */
  renameText = creatorText.renameText;
  /**Variable that holds submit text */
  submitText = creatorText.submit;
  /**Variable that holds name text */
  nameText = creatorText.Name;
  /*Variable that holds list of languages*/
  languages: any=[];
  /*Variable that holds the values selected from drop down */
  selectedValue:any=[];
  /*Variable that holds hides the table */
  hideTable:boolean=true;
  /*Variable that holds list of columns*/
  displayColumns:any=['Content Language','Country','Content Name'];
  /**Varaible which holds list of roles */
  roles: any = [];
  /**Varaible which holds User details */
  userDetails: any;
  /**Varaible which holds tableOffset value */
  tableOffset: number = 1;
  /**Varaible which holds tableLastPage value */
  tableLastPage: number = 0;
  /**Varaible which holds tableLastPage value */
  itemPerTable: number = 50;
  /**Varaible which holds tableDroupedData value */
  tableDroupedData!: any[];
  /**Varaible which holds displayedColumns value */
  displayedColumns: any = [];
  /**Varaible which holds searchdataSource value */
  searchdataSource!: MatTableDataSource<SearchDataOnlyone>;
  /**Varaible which holds decodedTableData value */
  decodedTableData: any = [];
  /**Varaible which holds decodedSearchTableData value */
  decodedSearchTableData: any = [];
  /**Varaible which holds searchdisplayedColumns value */
  searchdisplayedColumns: any = [];
  variantdisplayedColumns: any = [];
  /**Varaible which holds orderList value */
  orderList: any;
  /**Varaible which holds finalList value */
  finalList: any = [];
  /**Varaible which holds searchedList value */
  searchedList: any = null;
  /**Varaible which holds selected value */
  selected=-1;
  /**Varaible which holds shimmerType value */
  shimmerType = ['search-box','dropdown'];
  /**Varaible which holds isSearhBox value */
  dropdownShimmer: boolean = true;
  tableList: any[] = [];
  dataSource = new MatTableDataSource(this.tableList);
  variantClassName : string  ='';
  /** variable which holds layoutform data **/
  @Input('form') layoutForm!: FormGroup;
  @Input('layout') layout: any = [];
  isSearhBox = true;
  message = '';
  isCropChecked = false;
  isCropStageChecked = false;
  pageOffset: number = 0;
  docParserRequest: DocParserRequest;
  webScrapperRequest : WebScrapperRequest;
  switchroleRequest: SwitchRoleRequest;
  utilityError: boolean = false;
  decodedForm: any = {};
  name:string='';
  submitComment : string = '';
  /**Variable that holds renamed value*/
  submitRename : string = '';
  dataList:string[]=[];
  data:string='';
  arr1:any[]=[];
  tableMap:any=new Map();
  /*Variable that holds languages and its corressponding id */
  languageMap:any=new Map();
  isContent:boolean=false;
  errorArr:any[]=[];
  receivedData:any=[];
  text1:string='';
  utilityNoResults: boolean = false;
  viewMediaTable : any[] = []
  @Output() submitCommentEmitter = new EventEmitter<any>();
  @Output() submitRenameEmitter = new EventEmitter<any>();
  showTable:boolean=false;
  /**Variable that holds countries values */
  countries: any = [];
   /**Variable that holds show CountryShimmer boolean value */
   isCountry=true;
   /** Variable which holds the value of selectedLocation */
  selectedCountry: string = '';
  /*Variable that holds the response from translanguage api */
  transLanguage:any={};
  countryArr:any[]=[];
  countryCodeArr:any[]=[];
  contentName:any[]=[];
  /*Variable that holds static call button text */
  editLinkText=creatorText.editLinkText;
  /*Variable that holds static save text*/
  saveText=creatorText.saveText;
  /*Variable that holds static warning text*/
  warningText = creatorText.warningText;
  /*Variable that holds static selectValuesCaution text*/
  selectValuesCautionText = creatorText.selectValuesCautionText;
  /**Variable that holds logout warning heading */
  logoutText =  creatorText.logoutWarningHeadingText;
  /**Variable that holds logout warning message */
  logoutWarningMessage:string = '';

  /*Variable that holds call button object */
  callButtonObj={};
  apiSearchText : any = '';
  apiSearchTextMT1:any = '';
  selectAllMTM : boolean = false;
  /**Constructor which holds services and matdialog
   * @param {MatDialog}
   * @param {SessionStorageService}
   * @param {SwitchroleService}
   * @param {TemplateService}
   * @param {MatDialog}
   * @param {ManyToOneRelationService}
   * @param {MenuService}
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public activity: any,
    public dialogRef: MatDialogRef<DialogBoxComponent>,

    public sessionStorageService: SessionStorageService,
    public templateService: TemplateService,
    public matDialog: MatDialog,
    public manyToOneRelationService: ManyToOneRelationService,
    public mantToManyRelationService: ManyToManyRelationService,
    public switchrole: LoginService,
    public switchRoleService: SwitchroleService,
    private changeDetectorRefs: ChangeDetectorRef,
    public saveTemplateService: SaveTemplateService,
    private uitilityService : UitiltyService,
    public  transLanguageService:TranslanguageService,
    public headerService: HeaderService,
    public spinnerService: SpinnerService,
    public _snackBar: MatSnackBar,
    public menuService: MenuService
  
  ) {
    this.docParserRequest = new DocParserRequest();
    this.webScrapperRequest = new WebScrapperRequest();
    this.switchroleRequest = new SwitchRoleRequest();
  }

  /**ngOnint lifecycle hook */
  ngOnInit(): void {
    this.userDetails = this.sessionStorageService.get('userDetails');   
    this.roles = this.userDetails.roles;
    if(this.userDetails.roles?.length > 1){
      this.userRole = this.userDetails.workflowConfigurations[0].currentRole
    }else if(this.userDetails.roles?.length == 1){
      this.userRole = this.userDetails.roles[0].name
    }
    this.userRole = this.roles.filter(
      (data: any) => data.name == this.userRole
    )[0].id;
    if (this.activity.type == 'searchBoxMTMSelection') {
      this.getMTMData();
    }
    if (this.activity.type == 'searchBoxOneSelection') {
      this.getMT1Data();
    }
    if(this.activity.type == 'variantsList'){
      this.getVariantsList();
    }
    if(this.activity.type == 'utilityDialog'){
    }
    if(this.activity.type == 'viewMedia'){
      if(this.activity.value && this.activity.value.length > 0){
        this.viewMediaTable = [...this.activity.value]
        this.searchdataSource = new MatTableDataSource(this.viewMediaTable);
        this.searchdisplayedColumns = Object.keys(this.viewMediaTable[0]);
        this.searchdisplayedColumns.push('Link', 'Delete')
        this.changeDetectorRefs.detectChanges();
        this.layout.className = this.activity.className
      }else{
        this.message = "No Media as been added"
      }
        this.isSearhBox = false
    }
    if(this.activity.type == 'translate'){
      this.receivedData=this.activity.description;
      this.getLanguage();
      
    }
    if(this.activity.type == 'callButton'){
      if(this.activity.description && Object.keys(this.activity.description).length!=0){
        this.callButtonObj=this.activity.description;
      }
    }
   
    if(this.activity.type == 'menuRename'){
      this.submitRename = this.activity.value.name;
    }
    if(this.activity.type == 'logout'){
      this.logoutWarningMessage = this.activity.value.message
    }
  }
  /** Function to handle previousPage */
  selectAllMTMField(){
    this.selectAllMTM = !this.selectAllMTM
    if(this.searchdataSource.filteredData){
      this.searchdataSource.filteredData.forEach((rowData:any) => {
        if(!this.selectAllMTM){
          rowData.Checkbox = false
        }else{
          rowData.Checkbox = true
        }
        if(rowData.Checkbox){
          this.finalList.push(rowData);
        }else{
          this.finalList = this.finalList.filter((data:any)=> data.Id != rowData.Id)
        }
      });
    }
  }
  /** Function to handle previousPage */
  previousPage() {
    if (this.tableOffset != 0) {
      this.tableOffset--;
    }
    this.searchdataSource = new MatTableDataSource(
      this.decodedSearchTableData[this.tableOffset - 1]
    );
    this.changeDetectorRefs.detectChanges();
  }

  /** Function to handle updatePage */
  updatePage(event: any) {
    if (event.srcElement.value > this.tableLastPage)
      event.srcElement.value = this.tableLastPage;
    if (event.srcElement.value < 1) event.srcElement.value = 1;
    this.tableOffset = event.srcElement.value;
    this.getMT1Data();
  }
  
  /** Function to handle nextPage */
  nextPage() {
      this.tableOffset++;
      this.getMT1Data();
  }
  /** Function to handle updatePage */
  updatePageMTM(event: any) {
    if (event.srcElement.value > this.tableLastPage)
      event.srcElement.value = this.tableLastPage;
    if (event.srcElement.value < 1) event.srcElement.value = 1;
    this.tableOffset = event.srcElement.value;
    this.getMTMData();
  }
  /** Function to handle nextPage */
  nextPageMTM() {
    this.tableOffset++;
    this.getMTMData();
  }
  
  /** Function to handle switchrole */
  switchRole(id:string) {
      this.switchroleRequest.newRole = id;
      this.spinnerService.showOrhideSpinner(true);
      this.switchrole.switchRole(this.switchroleRequest).subscribe((data)=>{
      if(data.Response.success){
      if(this.userDetails.roles?.length > 1){
      this.userDetails.workflowConfigurations[0].currentRole = this.roles.filter(
        (data: any) => data.id == id
      )[0].name;
      this.userRole = this.userDetails.workflowConfigurations[0].currentRole
      }
      else if(this.userDetails.roles?.length == 1){
        this.userDetails.roles[0].name = this.roles.filter(
          (data: any) => data.id == id
        )[0].name;
        this.userRole =this.userDetails.roles[0].name
      }
      this.sessionStorageService.set('userDetails', this.userDetails);
      this.switchRoleService.setUserRole(this.userRole);  
      let errorObj = {
        message: data.Response.message,
        success:  data.Response.success
      }
      this.spinnerService.showOrhideSpinner(false);

        this.matDialog.open(SuccessErrorPopupComponent, { data: errorObj, disableClose: true });
      }
      else{
      this.spinnerService.showOrhideSpinner(false);

        let errorObj = {
          message: data.Response.error,
          success:  data.Response.success
        }
      this.matDialog.open(SuccessErrorPopupComponent, { data: errorObj, disableClose: true });
      }
    },
    (error: Error) => {
      this.spinnerService.showOrhideSpinner(false);
    });

  }
  /** Function to handle showData */
  selectDataMTM(orderList: any, ob: MatCheckboxChange) {
      if(orderList.Checkbox){
        this.finalList.push(orderList);
      }else{
        this.finalList = this.finalList.filter((data:any)=> data.Id != orderList.Id)
      }
  }
  /** Function to handle displayData */
  displayData() {
  }
  /** Function to handle selectData */
  selectDataMT1(searchList: any, ob: MatCheckboxChange, checkbox:any) {
    if(!checkbox){
      this.searchedList = ''
    }
    this.decodedSearchTableData.forEach((element: any) => {
      element.filter((data: any)=>
      {
        if(data.Id != searchList.Id){
          data.Checkbox= false;
        }
      })
    });
    if(!checkbox){
      this.searchedList = ''
    }else{
      this.searchedList = searchList;
    }
  }
  /** Function to handle selectedData */
  selectedData() {}
 /**
   * Function to handle filterTable
   * @param event event data
   */
  searchData(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchdataSource.filter = filterValue.trim().toLowerCase();
  }
 /**
   * Function to handle filterTable
   * @param event event data
   */
  searchAPIData() {
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;
    this.searchdataSource = new MatTableDataSource([])
    this.decodedSearchTableData = []
    this.tableOffset = 1
    this.getMTMData(true)
  }
  searchAPIDataMT1() {
    this.searchdataSource = new MatTableDataSource([]);
    this.decodedSearchTableData = [];
    this.tableOffset = 1;
    this.getMT1Data(true);
  }
  getMT1Data(searchParam?:any) {
    let params: any = {};
    this.message ='';
    if(searchParam){
      this.activity.value.dependencyValue['key'] = this.apiSearchTextMT1
    }
    if(this.apiSearchTextMT1 == ''){
      delete this.activity.value.dependencyValue['key']
    }
    if (JSON.stringify(this.activity.value.dependencyValue) != '{}') {
      params['search'] = `{"AND":${JSON.stringify(
        this.activity.value.dependencyValue
      )},"OR":{}}`;
    }
    params['start'] = (this.tableOffset - 1) * this.itemPerTable;
    params['pageSize'] = this.itemPerTable;
    params['className'] = this.activity.value.className;
    if (this.decodedSearchTableData.length >= this.tableOffset) {
      this.searchdataSource = new MatTableDataSource(this.decodedSearchTableData[this.tableOffset - 1]);
      this.changeDetectorRefs.detectChanges();
    } else {
      this.isSearhBox = true
      if(this.activity.value.apiType == 'master'){

      this.manyToOneRelationService
        .getManyToOneSearchData(params)
        .subscribe((res: any) => {
          if (res.response.success) {
            let tempTableData: any = [];
            res.response.data.data.forEach((list: any) => {
              if(this.activity.value.className == 'Country' || this.activity.value.className == 'BayerLanguage'){
                tempTableData.push({
                  Checkbox: false,
                  Name: list.Name,
                  Key: list.key,
                  AliasName: list.AliasName,
                  Id: list.id,
                  Path: list.path,
                });
              }else{
                tempTableData.push({
                  Checkbox: false,
                  Name: list.Name,
                  Key: list.key,
                  Id: list.id,
                  Path: list.path,
                })
              }
            });
            this.decodedSearchTableData[this.tableOffset - 1] = tempTableData;
            this.tableLastPage = Math.ceil(res.response.data.totalCount /  this.itemPerTable);
            this.decodedSearchTableData[this.tableOffset - 1].sort(this.sortTableData)
            this.searchdataSource = new MatTableDataSource(
              this.decodedSearchTableData[this.tableOffset - 1]
            );
            this.searchdisplayedColumns = Object.keys(
              this.decodedSearchTableData[this.tableOffset - 1][0]
            );
            this.changeDetectorRefs.detectChanges();
          } else {
            this.message = res.response.error;
          }

          this.isSearhBox = false;
        });
    }else{
      this.manyToOneRelationService
        .getManyToOneSearchDataContent(params)
        .subscribe((res:any)=>{
          if (res.response.success) {
            let tempTableData: any = [];
            res.response.data.data.forEach((list: any) => {
              tempTableData.push({
                Checkbox: false,
                Name: list.Name,
                Key: list.key,
                Id: list.id,
                Path: list.path
              });
            });
            this.decodedSearchTableData[this.tableOffset - 1] = tempTableData;
            this.tableLastPage = Math.ceil(res.response.data.totalCount /  this.itemPerTable);
            this.decodedSearchTableData[this.tableOffset - 1].sort(this.sortTableData)
            this.searchdataSource = new MatTableDataSource(
              this.decodedSearchTableData[this.tableOffset - 1]
            );
            this.searchdisplayedColumns = Object.keys(
              this.decodedSearchTableData[this.tableOffset - 1][0]
            );
            this.changeDetectorRefs.detectChanges();
          } else {
            this.message = res.response.error;
          }

          this.isSearhBox = false;
      })
    }
    }
  }
  getMTMData(searchParam?:any) {
    this.message = ''
    let params: any = {};
    // delete this.activity.value.dependencyValue['key']
    if(searchParam){
      this.activity.value.dependencyValue['key'] = this.apiSearchText
    }
    if(this.apiSearchText == ''){
      delete this.activity.value.dependencyValue['key']
    }
    if (JSON.stringify(this.activity.value.dependencyValue) != '{}') {
      params['search'] = `{"AND":${JSON.stringify(
        this.activity.value.dependencyValue
      )},"OR":${JSON.stringify(
        this.activity.value.dependencyValueMTM
      )}}`;
    }
    params['start'] = (this.tableOffset - 1) * this.itemPerTable;
    params['pageSize'] = this.itemPerTable;
    if(this.activity.value?.isDABayerMedia){
      this.itemPerTable = 100
      params['pageSize'] = this.itemPerTable;
      params['start'] = (this.tableOffset - 1) * this.itemPerTable;
    }else{
      this.itemPerTable = 50
    }
    params['className'] = this.activity.value.className;
    if (this.decodedSearchTableData.length >= this.tableOffset && !searchParam) {
      this.searchdataSource = new MatTableDataSource(this.decodedSearchTableData[this.tableOffset - 1]);
      this.changeDetectorRefs.detectChanges();
    }
    else{
      
    if (this.activity.value.apiType == 'contentType') {
      this.isSearhBox = true
      this.mantToManyRelationService
        .getManyToManySearchDataContentType(params)
        .subscribe((res: any) => {
          if (res.response.success) {
            let tempTableData: any = [];
            res.response.data.data.forEach((data: any) => {
              tempTableData.push({
                Checkbox: false,
                Name: data.Name,
                Key: data.key,
                Id: data.id,
                Path: data.path
              });
            });
            this.decodedSearchTableData[this.tableOffset - 1] = tempTableData;
            this.tableLastPage = Math.ceil(res.response.data.totalCount / this.itemPerTable);
            this.decodedSearchTableData[this.tableOffset - 1].sort(this.sortTableData)
            this.searchdataSource = new MatTableDataSource( this.decodedSearchTableData[this.tableOffset - 1]);
            this.searchdisplayedColumns = Object.keys(this.decodedSearchTableData[this.tableOffset - 1][0]);
            this.changeDetectorRefs.detectChanges();

          } else {
            this.message = res.response.error;
          }
          this.isSearhBox = false;
        });
      } else {
      this.isSearhBox = true
      this.mantToManyRelationService
        .getManyToManySearchData(params)
        .subscribe((res: any) => {

          if (res.response.success) {
            let tempTableData: any = [];
            res.response.data.data.forEach((data: any) => {
              tempTableData.push({
                Checkbox: '',
                Name: data.Name,
                Key: data.key,
                Id: data.id,
                Path: data.path
              });
            });
            this.decodedSearchTableData[this.tableOffset - 1] = tempTableData;
            this.tableLastPage = Math.ceil(res.response.data.totalCount / this.itemPerTable);
            this.decodedSearchTableData[this.tableOffset - 1].sort(this.sortTableData)
            this.searchdataSource = new MatTableDataSource( this.decodedSearchTableData[this.tableOffset - 1]);
            this.searchdisplayedColumns = Object.keys(this.decodedSearchTableData[this.tableOffset - 1][0]);
            this.changeDetectorRefs.detectChanges();
          } else {
            this.message = res.response.error;
          }

          this.isSearhBox = false;
        });
    }
    }
  }
  utilityProcessing(type : any){
    delete type.type
    this.changeDetectorRefs.detectChanges();
    if(this.activity.value.utilityType == 'Document Praser'){
      delete this.activity.value.utilityType
      this.docParserRequest.country = type.country == undefined ? this.showErrorMessage() : type.country.toString();
      if(this.isCropChecked){
        if(this.activity.value.crop){
          this.docParserRequest.crop = type.crop.toString();
        }else{
          this.showErrorMessage()
        }
      }
      else{
        this.utilityError = false;
        this.docParserRequest.crop = '';
      }
      if(this.isCropStageChecked){
        this.docParserRequest.cropstage = type.cropstage == undefined ? this.showErrorMessage() : type.cropstage;
      }
      else{
        this.docParserRequest.cropstage = [];
      }
      //Getting Selected Template
      if(this.uitilityService.webScrapperData?.length > 0){
        let templateTypesString = []
        this.uitilityService.webScrapperData.forEach(element => {
          templateTypesString.push(element.toString())
        });
        this.docParserRequest.templateTypes = templateTypesString
      }else{
        this.showErrorMessage()
      }
      if(!this.utilityError){
        this.dialogRef.close({ event: 'close',type: 'docParser', data: this.docParserRequest });
      }
    }
    else{
      delete this.activity.value.type
      delete this.activity.value.utilityType

      this.webScrapperRequest = this.activity.value
      if(this.isCropChecked){
        if(this.activity.value.crop){
          this.webScrapperRequest.crop = this.activity.value.crop.toString();
        }else{
          this.showErrorMessage()
        }
      }
      else{
        this.utilityError = false;
        this.webScrapperRequest.crop = '';
      }
        if(this.uitilityService.webScrapperData?.length > 0){
          let contentTypeString = []
          this.uitilityService.webScrapperData.forEach(element => {
            contentTypeString.push(element.toString())
          });
          this.webScrapperRequest.contentType = contentTypeString
        }else{
          this.showErrorMessage()
        }
      if(!this.utilityError){
        this.dialogRef.close({ event: 'close',type: 'WebScrapper', data: this.webScrapperRequest });

    }
  }
  }
  
  showErrorMessage(){
    this.utilityError = true;
  }
  getLanguage(){
    this.dropdownShimmer = true;
    let params: ContentTransLanguageRequest = {
        className:this.receivedData[1].split('_')[0],
        contentID: this.receivedData[0],
      };
      this.transLanguageService.gettransLanguage(params).subscribe((res: any) => {
      if(res.success==true && res.response.length!=0){
         this.transLanguage=res.response;
         if(this.languages.length!=0){
           this.languages=[];
         }
        res.response.forEach((ele:any)=>{this.languages.push(ele.AliasName);
                      this.languageMap.set(ele.AliasName,ele.id)});
         this.dropdownShimmer = false;
        }
        
        else{
          this.dropdownShimmer = false;
          this.languages = [
          ];
        }
      });
    }
   createContent(){
    this.text1='Processing';
    this.arr1=[];
    var languagecodeArr=[];
    var languageArr=[];
    var missing=[];
    for(const x of this.languageMap.values()){
      languagecodeArr.push(x);
    };
    for(const z of this.languageMap.keys()){
      languageArr.push(z);
    }
  for(let i=0;i<=this.dataList.length-1;i++){
   
      var form={
        "key":this.receivedData[1]+'_'+this.tableMap.get(this.dataList[i]),
        "className":this.receivedData[1].split('_')[0],
        "ParentObjectForTranslation_MT1":this.receivedData[0],
        "TranslationStatus":"1",
        "TranslationLanguage": this.languageMap.get(this.dataList[i]),
        "CreatorName" : this.sessionStorageService.get('userDetails').userName,
        "Name":this.tableMap.get(this.dataList[i]),
        "BayerLanguage_MT1":this.languageMap.get(this.dataList[i]),
        "Country_MT1":this.countryCodeArr[i],
        // "parentId":this.receivedData[0]
    
  }    
  this.arr1.push(form)
      };
      this.arr1.forEach((ele,index)=>{
        if(ele.Country_MT1!='country'){
          this.saveTemplateService.saveTemplate(ele).subscribe((res:any)=>{
            if(res.response.success!=true || ele.key.endsWith('undefined')){
                this.errorArr[index]='Error';
            }
            else{
              this.errorArr[index]='Success';
              for(const y of languagecodeArr){
                if(y==ele.TranslationLanguage){
                  let index=languagecodeArr.indexOf(y);
                  missing.push(languageArr[index]);
                  
                }
              }
              }
            if(this.errorArr.length==this.arr1.length){
              this.text1='';
              this.selectedValue=[];
              this.isContent=true;
              this.tableMap.clear();
              this.countryCodeArr=[];
              this.contentName=[];
              for(let i of missing){
                this.languages=this.languages.filter(item=>!missing.includes(item))
              }              
            }
          });
        }
        else{
          this.errorArr[index]='Error';
          if(this.errorArr.length==this.arr1.length){
            this.text1='';
            this.selectedValue=[];
            this.isContent=true;
            this.tableMap.clear();
            this.countryCodeArr=[];
            this.contentName=[];
            for(let i of missing){
              this.languages=this.languages.filter(item=>!missing.includes(item))
            }
            
          }
        }
        
     });
  }
 onClose(){
  this.showTable=true;
  this.dataList.forEach((ele,i)=>{
    if(this.transLanguage.response!=[]){
      this.transLanguage.forEach(element=>{if(element.AliasName==this.dataList[i])
        {
          this.contentName[i]=this.activity.templateDetails['name']+'_'+element.Name;
        }});
        this.tableMap.set(this.dataList[i],this.contentName[i]);
      }
    this.getCountry(ele,i);
  });
  }
     
  onKeyup(i:any){    
      this.tableMap.set(this.dataList[i],this.contentName[i]);
    }

onChange(){
  this.errorArr=[];
  this.isContent=false;
  this.selectedValue.forEach((ele:any) => {
    if (!this.dataList.includes(ele)) {
      this.dataList.push(ele);
    }
    
});
this.dataList= this.dataList.filter(item => this.selectedValue.includes(item));
}
 deleteTableData(index: number){
    this.viewMediaTable.splice(index, 1);
    this.searchdataSource = new MatTableDataSource(this.viewMediaTable);
    this.changeDetectorRefs.detectChanges();
  }
   /**function to open in new tab */
 openInNewTab(id: number, name: string) {
  this.headerService.setMenuItemInfo({
    id: id,
    name: name,
    className: this.layout.className,
    fromMenu: true,
  });
}
  formSubmit(){
    this.message = 'Please add the comment to submit'
    if(this.submitComment != ''){
      this.message = ''
      this.dialogRef.close({ event: 'close', data: this.submitComment });
    }
  }
  renameSubmit(){
    this.spinnerService.showOrhideSpinner(true);
    this.message = 'Please give valid name'
    if(this.submitRename != ''){
      this.message = ''
      this.decodedForm['key'] = this.submitRename;
      this.decodedForm['className'] = this.activity.value.className;
      this.decodedForm['id'] = this.activity.value.id;
      if(this.activity.value.parent == 'variant-file'){
        this.decodedForm['parentId'] = this.activity.value.parentId
      }
      this.saveTemplateService.saveTemplate(this.decodedForm).subscribe(
        (res: any) => {
          if (res.response.success) {
            this.dialogRef.close({ event: 'close', data: this.submitRename });
            this.openSnackBar(
              { type: 'success', message: 'Renamed successfully!' },
              3000
            );
              this.menuService.setRenameAutoSaveEnabledSubject(this.decodedForm);
              this.spinnerService.showOrhideSpinner(false);
          } else {
            this.openSnackBar(
              {
                type: 'error',
                message: 'Error occured while renaming the Content!',
              },
              3000
            );
            this.dialogRef.close({ event: 'close', data: '' });
            this.spinnerService.showOrhideSpinner(false);
          }
        },
        (err) => {
          this.openSnackBar(
            {
              type: 'error',
              message: 'Error occured while renaming the Content!',
            },
            3000
          );
          this.dialogRef.close({ event: 'close', data: '' });
          this.spinnerService.showOrhideSpinner(false);
        }
      );
    }
  }

  openSnackBar(data: any, duration: number) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      data: data,
      duration: duration,
    });
  }
  method(){
    clearTimeout(timer1);
    var timer1=setTimeout(()=>{if(this.transLanguage.response==[] || this.languages.length==0){
      this.openSnackBar(
        { type: 'error', message: 'No translation language found!' },
        5000
      );
     
    }
  },3000);
    
  }
   /** Function to get location values */
   getCountry(language:any,i:any) {
    this.isCountry=true;
    this.countries=[];
    var languageName;
    var langId;
    this.transLanguage.forEach(ele=>{if(ele.AliasName==language)
    {
      languageName=ele.Name;
      langId = ele.id;
    }})
    let params: any = {};
    params['className'] = "Country";
    params['search'] = `{"AND":{"BayerLanguage_MTM_Value":"${languageName}","BayerLanguage_MT1":"${langId}"},"OR":{}}`
    this.manyToOneRelationService.getManyToOneSearchData(params).subscribe((res: any) => {
      if(res.response.success){
      this.countries = res.response.data.data;
      
      this.isCountry=false;
      }
      else{
        this.countries = [{
          "id":"country",
          "key":"No data found"
        }];
        this.isCountry=false;
      }
      this.countryArr[i]=this.countries;
    });
  }
  selectCountry(i:any,event:any){
    this.countryCodeArr[i]=event.value;
  }

  getVariantsList(){
    this.dataSource = this.activity.value.list;
    this.variantdisplayedColumns = this.activity.value.displayedColumns;
    this.variantClassName = this.activity.value.className;
  }
  openVariantNewTab(id: number, name: string) {
    this.dialogRef.close({ event: 'close',type: 'variantsList'});
    this.headerService.setMenuItemInfo({
      id: id,
      name: name,
      className: this.variantClassName,
      fromMenu: true,
      isVarient: true,
      parentId : this.activity.value.parentId
    });
  }
  save(text,path,title){
    this.callButtonObj['text']=text;
    this.callButtonObj['path']=path;
    this.callButtonObj['title']=title;
    this.dialogRef.close(this.callButtonObj);
  }
  close(){
    this.dialogRef.close(this.callButtonObj);
  }

  sortTableData(a, b) {
      const name1 = a.Name.toUpperCase();
      const name2 = b.Name.toUpperCase();
      let comparison = 0;
      if (name1 > name2) {
          comparison = 1;
      } else if (name1 < name2) {
          comparison = -1;
      }
      return comparison;
  }
}
