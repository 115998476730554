import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: 'content-curator', loadChildren: () => import('./content-curator/content-curator.module').then(m => m.ContentCuratorModule) },
  { path: 'content-creator', loadChildren: () => import('./content-creator/content-creator.module').then(m => m.ContentCreatorModule) },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login', component: LoginComponent }
];
const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
