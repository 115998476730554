import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ContentTransLanguageRequest } from '../../models/Translation/content-translanguage-request';
import { Observable } from 'rxjs';
import { GlobalValuesService } from '../global-values/global-values.service';
import { SessionStorageService } from 'angular-web-storage';
/**Component which holds TranslanguageService */
@Injectable({
  providedIn: 'root'
})
/**Component which holds TranslanguageService */
export class TranslanguageService {
 /**Constructor which holds httpclient */
  constructor(private http:HttpClient,public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService) { }
    /**Variable which holds data */
  data:any=[];
   /**Variable which holds transLanguage Url */
  transLanguageUrl: string = this.globalValue.creatorBaseURL + 'content/translanguage';
/** function is to get transLanguage */
  gettransLanguage(params:any): Observable<ContentTransLanguageRequest> {
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({ 
      token: sessionData.token,
    }); 
    return this.http.get<ContentTransLanguageRequest>(this.transLanguageUrl, 
      {params:params,headers:headers}
    );
  }
  /** function is to get send Data */
  sendData(response:any,str1:string){
    this.data=[];
    this.data.push(response);
    this.data.push(str1);
    // console.log(this.data); 
  }
 /** function is to get get Data */
  getData(){
    return this.data;
  }
  
}
