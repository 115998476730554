import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalValuesService } from '../global-values/global-values.service';
import { Observable } from 'rxjs/internal/Observable';
import { SessionStorageService } from 'angular-web-storage';

import { ContentDetailResponse } from '../../models/template/content-detail/content-detail.response';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
/** Service to get all Template details  */
export class TemplateService {
  /**
   * Constructor which holds httpclient and service
   * @param http HTTPClient imports
   * @param {GlobalValuesService}
   */
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}
  private updateReadonly = new Subject<any>();

  /**Variable which holds ListFaq API url */
  classDefinitionUrl: string = this.globalValue.creatorBaseURL + '/class/definition?className=';
  /**Function to handle getTemplate*/
  getClassDefinition(className: string) {
    return this.http.get(this.classDefinitionUrl + className);
  }

  // /**Function to handle getUpdatedTemplate*/
  // getUpdatedTemplate(handler: string) {
  //   return this.http.get('/assets/json/' + handler + '.json');
  // }

  /**Variable which holds ListFaq API url */
  contentDetailUrl: string = this.globalValue.creatorBaseURL + 'content/detail?id=';
  /**Function to handle getTemplate*/
  getContentDetail(id: number): Observable<ContentDetailResponse>{
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({ 
      token: sessionData.token
    }); 
    return this.http.get<ContentDetailResponse>(this.contentDetailUrl + id,{ headers });
  }

/**function to  get RecycleBin Data*/
  getRecycleBinData(){
    return this.http.get('/assets/json/recyclebin.json');
  }


 /**Variable which holds ListFaq API url */
 deleteContentUrl: string = this.globalValue.creatorBaseURL + 'content/delete?id=';
 /**Function to handle getTemplate*/
  deleteContent(id:number){
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({ 
      token: sessionData.token
    }); 
    return this.http.delete(this.deleteContentUrl + id,{ headers });
  }

    /**Function to handle setMenuItemInfo */
    setupdateReadonly(val: any): void {
      this.updateReadonly.next(val);
    }
    /**Function to handle getMenuItemInfo */
    getupdateReadonly(): Observable<any> {
      return this.updateReadonly.asObservable();
    }
}
