import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { GlobalValuesService } from '../global-values/global-values.service';

@Injectable({
  providedIn: 'root'
})
/** Service to get all header details  */
export class HeaderService {
  /**Varaible which holds boolean value of showSideNav */
  showSideNav: boolean = false;
  /**Varaible which holds value of currentTab */
  currentTab: any;
  /**Varaible which holds value of subject */
  private subject = new Subject<any>();
  /**Varaible which holds value of menuSubject */
  private menuSubject = new Subject<any>();

  private langSubject = new Subject<any>();
  private sessionSubject = new Subject<any>();
  /**
   * Constructor which holds httpclient
   * @param http HTTPClient imports
   */
  constructor(private http: HttpClient, public globalValue: GlobalValuesService,) { }
  
  /**Varaible which holds value of baseURL */
  baseURL = 'http://127.0.0.1/admin/asset/tree-get-childs-by-id?_dc=1635502720379&limit=100&view=&fromPaging=0&start=0&node=355'
  /**Function to get menu data */
  getMenuData(node: string, offset: number) {
    // return this.http.get(this.baseURL)
    return this.http.get('/assets/json/' + node + '-' + offset + '.json')
  }
  /**Function to handle toggleSideNav */
  toggleSideNav(): void {
    this.showSideNav = !this.showSideNav;
  }
  /**Function to handle setCurrentTab */
  setCurrentTab(val: any): void {
    this.subject.next(val);
  }
  /**Function to handle selectedTab */
  selectedTab(): Observable<any> {
    return this.subject.asObservable();
  }
  /**Function to handle setMenuItemInfo */
  setMenuItemInfo(val: any): void {
    this.menuSubject.next(val);
  }
  /**Function to handle getMenuItemInfo */
  getMenuItemInfo(): Observable<any> {
    return this.menuSubject.asObservable();
  }

  /**Function to handle setMenuItemInfo */
  setLangItemInfo(val: any): void {
    this.langSubject.next(val);
  }
  /**Function to handle getMenuItemInfo */
  getLangtemInfo(): Observable<any> {
    return this.langSubject.asObservable();
  }
  faqURL: string = this.globalValue.creatorBaseURL + 'get/constants'
   getConstants():Observable<any> {
    return this.http.get(this.faqURL);
   }

  /**Function to handle setMenuItemInfo */
  setSessionInfo(val: any): void {
    this.sessionSubject.next(val);
  }
  
  /**Function to handle getMenuItemInfo */
  getSessionInfo(): Observable<any> {
    return this.sessionSubject.asObservable();
  }
}
