<div *ngIf="msg.success">
    <div class="container">
        <div class="row heading">
            <div class="pt-2 col text-center">
                <p class="text-uppercase b-body-04 text-gray-scale-05">{{success}}</p>
            </div>
            <div>
                <p mat-dialog-close class="text-uppercase text-gray-scale-05 close-btn pt-1 pr-2">&#x2715;</p>
            </div>
        </div>
        <div class="row p-3">
            <h2 class="m-auto">{{msg.message}}</h2>
        </div>
    </div> 
</div>

<div *ngIf="!msg.success">
    <div class="container">
        <div class="row heading">
            <div class="pt-2 col text-center">
                <p class="text-uppercase b-body-04 text-gray-scale-05">{{error}}</p>
            </div>
            <div>
                <p mat-dialog-close class="text-uppercase text-gray-scale-05 close-btn pt-1 pr-2">&#x2715;</p>
            </div>
        </div>
        <div class="row p-3">
            <h2 class="m-auto">{{msg.message}}</h2>
        </div>
    </div> 
</div>