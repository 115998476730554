import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { creatorText } from 'src/app/content-creator/creator.messages';
import { ExternalLoginRequest } from '../content-creator/models/Login/external-login/external-login-request';
import { InternalLoginRequest } from '../content-creator/models/Login/internal-login/internal-login-request';
import { LoginService } from '../content-creator/services/login/login.service';
import { SessionStorageService, SessionStorage } from 'angular-web-storage';
import { SuccessErrorPopupComponent } from '../content-curator/components/common/success-error-popup/success-error-popup.component';

import { SpinnerService } from '../content-creator/services/spinner/spinner.service';
import { MsalService } from '@azure/msal-angular';

import { MatDialog } from '@angular/material/dialog';
import { SwitchroleService } from '../content-creator/services/header/switchrole.service';
import { MasterListContentTrackerService } from '../content-curator/services/content-tracker/master-list-content-tracker.service';
import { HeaderService } from '../content-creator/services/header/header.service';
/**Component which holds Login functionality */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  /**Variable that holds externaloginRequest*/
  externaloginRequest: ExternalLoginRequest;
  /**Variable that holds internaloginRequest*/
  internaloginRequest: InternalLoginRequest;

  /**Variable that holds static Agrow text*/
  agrowText = creatorText.agrowText;
  /**Variable that holds static Smart text*/
  smartText = creatorText.smartText;
  /**Variable that holds static Agrow Caption text*/
  agrowCaption = creatorText.agrowCaption;
   /**Variable that holds static Username text*/
 userNameValidation = creatorText.userNameValidation;
 /**Variable that holds static Username text*/
 userNameTouched = creatorText.userNameTouched;
  /**Variable that holds static Password text*/
  passwordValidation = creatorText.passwordValidation;
  /**Variable that holds static Password condition text*/
  passwordCharacterCheck= creatorText.passwordCharacterCheck;
  /**Variable that holds static externalLogin text*/
  externalLoginButton= creatorText.externalLoginButton;
  /**Variable that holds static internalLogin text*/
  internalLoginButton = creatorText.internalLoginButton;
  /**Variable that holds static CopyRight text*/
  copyRightText = creatorText.copyRightText;
  /**Variable that holds static Bayer Content text*/
  bayerContentText = creatorText.bayerContentText;
 
  loginDisplay = false;
  /**Variable which holds form group values */
  @ViewChild('loginForm', { static: true }) loginForm!: any;

  /**Creates an instance of documenter
   * @param {loginServiceProvider}
   * @param {Router}
   * @param {SessionStorageService}
   */
  constructor(
    public loginServiceProvider: LoginService,
    private router: Router,
    public sessionStorageService: SessionStorageService,
    public spinnerService: SpinnerService,
    private authService: MsalService,
    public dialog: MatDialog,
    public masterlistservice:MasterListContentTrackerService,
    public switchRoleService: SwitchroleService,
    public headerService: HeaderService
  ) {
    this.externaloginRequest = new ExternalLoginRequest();
    this.internaloginRequest = new InternalLoginRequest();
  }

  /**ngOnInit lifecycle hook */
  ngOnInit() {
    this.sessionStorageService.clear()
  }
  

  /**Function that handles internalLogin */
  internalLogin() {
    this.spinnerService.showOrhideSpinner(true);
    this.internaloginRequest.cwid = this.loginForm.controls['u_name'].value;
    this.authService.loginPopup()
    .subscribe({
      next: (result : any) => {
        this.internaloginRequest.cwid = result.account.username;
        /**Akana platform API - Bearer API */
        this.loginServiceProvider.getAkanaToken().subscribe(
      (resdata: any) => {
        this.sessionStorageService.set(
          'bearerToken',
          resdata.response
        );
        this.headerService.setSessionInfo(false);
        this.sessionStorageService.set('UserLoginTime', new Date());
        /**External API - username and password */
        this.loginServiceProvider
          .internalLogin(this.internaloginRequest)
          .subscribe(
            (data: any) => {
              if(data.response.success){
                this.sessionStorageService.set('userToken', data.response.data);
                /**Get userdetail API */
                this.loginServiceProvider.userDetail().subscribe(
                  (res: any) => {
                    let userDetails = res.Response.data
                    if(userDetails.roles?.length > 1){
                      userDetails['currentRole'] = userDetails.workflowConfigurations[0].currentRole
                    }else if(userDetails.roles?.length == 1){
                      userDetails['currentRole'] = userDetails.roles[0].name
                    }
                    
                    if(userDetails.workflowConfigurations.length==0) {
                      this.sessionStorageService.set('userDetails', userDetails);
                      if(userDetails.userName === "ContentPortalAdmin" ||
                         userDetails.userName === "admin") {
                           this.router.navigate(['/content-curator/dashboard']);
                         }
                         else {
                           this.router.navigate(['/content-creator/panel/dashboard']);
                         }
                    }
                    else {  
                      // master list
                      let arr = [
                        {
                          'key':'languages',
                          'value':'BayerLanguage'
                        },
                        {
                          'key':'countries',
                          'value':'Country'
                        },
                        {
                          'key':'crops',
                          'value':'Crop'
                        },
                        {
                          'key':'contentTypes',
                          'value':'ContentType'
                        }
                      ]
                      let queryParm1 = `{"AND":{},"OR":{}}`;
                      let ids = []
                      for(let k=0;k<4;k++){
                        let key = userDetails.workflowConfigurations[0][arr[k]['key']]
                        if(key.length == 0) {
                          userDetails.workflowConfigurations[0][arr[k]['value']] = []
                          this.sessionStorageService.set('userDetails', userDetails);
                        }
                        else {
                          if(key[0].id) {
                            for(let i=0;i<key.length;i++){
                              ids.push(key[i]['id'])
                            }
                            userDetails.workflowConfigurations[0][arr[k]['value']] = ids
                            this.sessionStorageService.set('userDetails', userDetails);
                            if(k==0){
                              if(userDetails.userName === "ContentPortalAdmin" ||
                              userDetails.userName === "admin") {
                                this.router.navigate(['/content-curator/dashboard']);
                              }
                              else {
                                this.router.navigate(['/content-creator/panel/dashboard']);
                              }
                            }
                          }
                          else {
                            this.masterlistservice.getMasterList(arr[k]['value'], queryParm1, '0', '25').subscribe((data : any) => {
                              let res =  data.response.data.data;
                              for(let i=0;i<key.length;i++){
                                for(let j=0;j<res.length;j++){
                                  if(key[i]==res[j]['AliasName']||key[i]==res[j]['key']||key[i]==res[j]['Name']){
                                    ids.push(res[j]['id'])
                                    break;
                                  }
                                }
                              }
                              userDetails.workflowConfigurations[0][arr[k]['value']] = ids
                              this.sessionStorageService.set('userDetails', userDetails);
                              if(k==0){
                                if(userDetails.userName === "ContentPortalAdmin" ||
                                userDetails.userName === "admin") {
                                  this.router.navigate(['/content-curator/dashboard']);
                                }
                                else {
                                  this.router.navigate(['/content-creator/panel/dashboard']);
                                }
                              }
                            });
                          }
                        }
                      }
                    }

                    if(res.Response.data.roles.length > 1){
                      this.switchRoleService.setUserRole(res.Response.data.workflowConfigurations[0].currentRole);
                    }else{
                      this.switchRoleService.setUserRole(res.Response.data.roles[0].name);
                    }
                    
                  },
                  (error: Error) => {
                    this.spinnerService.showOrhideSpinner(false);
                  }
                );
                }
                else{
                  this.spinnerService.showOrhideSpinner(false);
                    let message : string =''
                    message = "CWID is not Valid";
                    
                    let errorObj = {
                      message: message,
                      success:  data.response.success
                    }
                  this.dialog.open(SuccessErrorPopupComponent, { data: errorObj, disableClose: true });
                }
              },
              (error: Error) => {
                this.spinnerService.showOrhideSpinner(false);
                });
          },
          (error: Error) => {
            this.spinnerService.showOrhideSpinner(false);
            let errorObj = {
              message: "Please try again later",
              success:  false,
            };
            this.dialog.open(SuccessErrorPopupComponent, { data: errorObj, disableClose: true});
          }
        );
        },
        error: (error) => {
          this.dialog.open(SuccessErrorPopupComponent, { data: error, disableClose: true});
        }
      })
    }
                 
  /**Function that handles externalLogin */
  externalLogin() {
    this.spinnerService.showOrhideSpinner(true);

    this.externaloginRequest.userName = this.loginForm.controls['u_name'].value;
    this.externaloginRequest.password = this.loginForm.controls['pass'].value;
    /**Akana platform API - Bearer API */
    this.loginServiceProvider.getAkanaToken().subscribe(
      (resdata: any) => {
        this.sessionStorageService.set(
          'bearerToken',
          resdata.response
        );
        this.headerService.setSessionInfo(false);
        this.sessionStorageService.set('UserLoginTime', new Date());
        /**External API - username and password */
        if( this.externaloginRequest.userName != '' &&  this.externaloginRequest.password != ''){
        this.loginServiceProvider
          .externalLogin(this.externaloginRequest)
          .subscribe(
            (data: any) => {
              if(data.response.success){
              this.sessionStorageService.set('userToken', data.response.data);
              /**Get userdetail API */
              this.loginServiceProvider.userDetail().subscribe(
                (res: any) => {
                  let userDetails = res.Response.data
                  if(userDetails.roles?.length > 1){
                    userDetails['currentRole'] = userDetails.workflowConfigurations[0].currentRole
                  }else if(userDetails.roles?.length == 1){
                    userDetails['currentRole'] = userDetails.roles[0].name
                  }
                  
                if(userDetails.workflowConfigurations.length==0) {
                  this.sessionStorageService.set('userDetails', userDetails);
                  if(userDetails.userName === "ContentPortalAdmin" ||
                     userDetails.userName === "admin") {
                       this.router.navigate(['/content-curator/dashboard']);
                     }
                     else {
                       this.router.navigate(['/content-creator/panel/dashboard']);
                     }
                }
                else {  
                  // master list
                  let arr = [
                    {
                      'key':'languages',
                      'value':'BayerLanguage'
                    },
                    {
                      'key':'countries',
                      'value':'Country'
                    },
                    {
                      'key':'crops',
                      'value':'Crop'
                    },
                    {
                      'key':'contentTypes',
                      'value':'ContentType'
                    }
                  ]
                  let queryParm1 = `{"AND":{},"OR":{}}`;
                  let ids = []
                  for(let k=0;k<4;k++){
                      ids = []
                      let key = userDetails.workflowConfigurations[0][arr[k]['key']]
                      if(key.length == 0) {
                        userDetails.workflowConfigurations[0][arr[k]['value']] = []
                            this.sessionStorageService.set('userDetails', userDetails);
                      }
                      else {
                        if(key[0].id) {
                          for(let i=0;i<key.length;i++){
                            ids.push(key[i]['id'])
                          }
                          userDetails.workflowConfigurations[0][arr[k]['value']] = ids
                          this.sessionStorageService.set('userDetails', userDetails);
                          if(k==0){
                            if(userDetails.userName === "ContentPortalAdmin" ||
                            userDetails.userName === "admin") {
                              this.router.navigate(['/content-curator/dashboard']);
                            }
                            else {
                              this.router.navigate(['/content-creator/panel/dashboard']);
                            }
                          }
                        }
                        else {
                          this.masterlistservice.getMasterList(arr[k]['value'], queryParm1, '0', '25').subscribe((data : any) => {
                            let res =  data.response.data.data;
                            for(let i=0;i<key.length;i++){
                              for(let j=0;j<res.length;j++){
                                if(key[i]==res[j]['AliasName']||key[i]==res[j]['key']||key[i]==res[j]['Name']){
                                  ids.push(res[j]['id'])
                                  break;
                                }
                              }
                            }
                            userDetails.workflowConfigurations[0][arr[k]['value']] = ids
                            this.sessionStorageService.set('userDetails', userDetails);
                            if(k==0){
                              if(userDetails.userName === "ContentPortalAdmin" ||
                              userDetails.userName === "admin") {
                                this.router.navigate(['/content-curator/dashboard']);
                              }
                              else {
                                this.router.navigate(['/content-creator/panel/dashboard']);
                              }
                            }
                          });
                        }
                      }
                    }
                }

                  if(res.Response.data.roles.length > 1){
                    this.switchRoleService.setUserRole(res.Response.data.workflowConfigurations[0].currentRole);
                  }else{
                    this.switchRoleService.setUserRole(res.Response.data.roles[0].name);
                  }

                },
                (error: Error) => {
                  this.spinnerService.showOrhideSpinner(false);
                }
              );
              }
              else{
                this.spinnerService.showOrhideSpinner(false);
                  let message : string =''
                  message = "Enter valid Username and Password";
                  
                  let errorObj = {
                    message: message,
                    success:  data.response.success
                  }
                this.dialog.open(SuccessErrorPopupComponent, { data: errorObj, disableClose: true });
              }
            },
            (error: Error) => {
              this.spinnerService.showOrhideSpinner(false);
            });
          } else{
            this.spinnerService.showOrhideSpinner(false);
            let errorObj = {
              message: "Enter Username and Password",
              success:  false,
            };
            this.dialog.open(SuccessErrorPopupComponent, { data: errorObj, disableClose: true });
          } 
      },
      (error: Error) => {
        this.spinnerService.showOrhideSpinner(false);
        let errorObj = {
          message: "Please try again later",
          success:  false,
        };
        this.dialog.open(SuccessErrorPopupComponent, { data: errorObj, disableClose: true});
      }
    );
  }
}
