import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
/** Interface which holds MasterListContentTrackerService */
export class MasterListContentTrackerService {
 /**varaible   which holds baseUrl */
  baseUrl = environment.baseUrl
  /**varaible   which holds setPath */
  setPath = "contenttracker/master/list";

   /**
   * Constructor which holds httpclient and service
   * @param http HTTPClient imports 
   */
  constructor(private http: HttpClient) { }

  /**Function to handle getMasterList*/
  getMasterList(classname:any,search:any,start:any,pagesize:any):Observable<any> {
    let url = this.baseUrl + this.setPath
    return this.http.get(url,{ params: { className:classname, search:search, start:start, pageSize:pagesize} })
   }
}
