
<div [ngClass]="{'success':data.type=='success','error':data.type=='error'}">
    <div class="snakbar-container  position-relative d-flex align-items-center" >
        <div class="position-relative icon-cont">

            <div class="circle" [ngClass]="data.type=='progress'?'background-primary-mid-dark-color':'background-bayer-white'" >
    
            </div>
            
            <ng-conatiner >
                <div *ngIf="data.type=='success'">
                    
                    <img src="/assets/icons/tick-green.svg" alt="" >
                    
                </div>
                <div *ngIf="data.type =='error'">
                    <img src="/assets/icons/warning-red.svg" alt="" >
                </div>
                <div *ngIf="data.type=='progress'"  class="center spinner">
                    <mat-progress-spinner diameter=16
                    mode="indeterminate" value="100"></mat-progress-spinner>
                </div>
            </ng-conatiner >
        </div>
             <div class="snack-bar-msg  b-body-16" [ngClass]="{'text-bayer-green':data.type=='success','text-primary-mid-light-color':data.type=='progress','text-bayer-red':data.type=='error'}">
                {{data.message}}
             </div>
       
      
        
        
    </div>
</div>