import { Component, Input, OnInit } from '@angular/core';

/**Component which holds shimmer on UI load */
@Component({
  selector: 'app-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.scss']
})
/**Class which holds shimmer on UI load */
export class ShimmerComponent implements OnInit {
  /**Variable which holds type of shimmer*/
  @Input() shimmerType : string = "";
  /**Creates an instance of documenter */
  constructor() { }
  /**Variable which holds number upto 10 */
  tenBlocks = [1,2,3,4,5,6,7,8,9,10]
  /**Variable which holds number upto 6 */
  sixBlocks = [1,2,3,4,5,6]
  /**Variable which holds number upto 14 */
  images=[1,2,3,4,5,6,7,8,9,10,11,12,13,14]
  /**Variable which holds number upto 2 */
  twoBlocks= [1,2]
  /**Variable which holds number upto 6 */
  treeData= [1,2,3,4,5,6]
  /**Variable which holds number upto 4 */
  fourBlocks= [1,2,3,4]
  /**Variable which holds number upto 5 */
  fiveBlocks=[1,2,3,4,5]
  /** ngOninit lifecycle hook */
  ngOnInit(): void {
  }

}
