import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExternalLoginResponse } from '../../models/Login/external-login/external-login-response';
import { ExternalLoginRequest } from '../../models/Login/external-login/external-login-request';
import { InternalLoginRequest } from '../../models/Login/internal-login/internal-login-request';
import { InternalLoginResponse } from '../../models/Login/internal-login/internal-login-response';
import { UserDetailResponse } from '../../models/Login/user-details/user-details-response';
import { GlobalValuesService } from '../global-values/global-values.service';
import { SwitchRoleResponse } from '../../models/Login/switch-role/switch-role-response';
import { SwitchRoleRequest } from '../../models/Login/switch-role/switch-role-request';
import { FaqDocsResponse } from '../../models/Login/faq-docs/faq-docs-response';
import { AkanaLoginResponse } from '../../models/Login/akana-login/akana-login-response';
import { SessionStorageService } from 'angular-web-storage';
/**Component which holds LoginService functionality */

@Injectable({
  providedIn: 'root',
})
/** Service to get all login details  */
export class LoginService {
  /**Creates an instance of documenter
   * @param {HttpClient}
   * @param {GlobalValuesService}
   * @param {SessionStorageService}
   */
  constructor(
    public http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  /**Variable which holds internalLoginUrl */
  internalLoginUrl: string = this.globalValue.creatorBaseURL + 'user/cwidlogin';
  /**Function to handle internalLogin
   * @param loginReqParam
   */
  internalLogin(
    loginReqParam: InternalLoginRequest
  ): Observable<InternalLoginResponse> {
    return this.http.post<InternalLoginResponse>(
      this.internalLoginUrl,
      loginReqParam
    );
  }
  
  /**Variable which holds externalLoginUrl */
  externalLoginUrl: string = this.globalValue.creatorBaseURL + 'user/login';
  /**Function to handle externalLogin
   * @param loginReqParam
   */
  externalLogin(
    loginReqParam: ExternalLoginRequest
  ): Observable<ExternalLoginResponse> {
    // console.log('Params is', loginReqParam);
    return this.http.post<InternalLoginResponse>(
      this.externalLoginUrl,
      loginReqParam
    );
  }

  /**Variable which holds userDetailUrl*/
  userDetailUrl: string = this.globalValue.creatorBaseURL + 'user/detail';
  /**Function to handle userDetail */
  userDetail(): Observable<UserDetailResponse> {
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({ token: sessionData.token });
    return this.http.get<UserDetailResponse>(this.userDetailUrl, { headers });
  }

  /**Variable which holds logOutUrl */
  logOutUrl: string = this.globalValue.creatorBaseURL + 'user/logout';
  /**Function to handle logOut */
  logOut() {
    return this.http.get(this.logOutUrl);
  }

  /**Variable which holds switchRoleUrl */
  switchRoleUrl: string = this.globalValue.creatorBaseURL + 'user/switch-role?newRole=';
  /**Function to handle switchRole
   * @param switchRoleParam
   */
  switchRole(switchRoleParam: SwitchRoleRequest): Observable<SwitchRoleResponse> {
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({ token: sessionData.token });

    return this.http.put<SwitchRoleResponse>(
      this.switchRoleUrl + switchRoleParam.newRole, {},{headers}
    );
  }

  /**Variable which holds ListFaq API url */
  listFaqsUrl: string = this.globalValue.creatorBaseURL + 'faqs/faqdetails';
  /**Function to get ListFaqs */
  listFaqs(): Observable<FaqDocsResponse> {
    return this.http.get<FaqDocsResponse>(this.listFaqsUrl);
  }
  /**Variable which holds akanaUrl API url */
  akanaUrl: string = this.globalValue.creatorBaseURL + 'bearer/token';
  /**Function to get getAkanaToken */
  getAkanaToken(): Observable<AkanaLoginResponse> {
    return this.http.get<AkanaLoginResponse>(this.akanaUrl);
  }
}
