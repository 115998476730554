import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionStorageService } from 'angular-web-storage';
import { environment } from 'src/environments/environment';
import { DialogBoxComponent } from './content-creator/components/common/dialog-box/dialog-box.component';
import { HeaderService } from './content-creator/services/header/header.service';
/**Component which holds Component */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
/**Class which holds AppComponent title */
export class AppComponent {
  /**varaible omponent which holds title */
  title = 'contentCreatorPortal';
  isIframe = false;
  id :any;
  logoutWarningShown:Boolean = false;
  difference:any;
  constructor(public sessionStorageService: SessionStorageService,public dialog: MatDialog,public headerService: HeaderService){}
  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.headerService.setSessionInfo(false);
    this.headerService.getSessionInfo().subscribe((value: any) =>{
      this.logoutWarningShown = value;
    this.id = setInterval(() => {
    let token=this.sessionStorageService.get('bearerToken');
    if(token && !this.logoutWarningShown){
    if (this.tokenExpiredAlert(token.access_token,token.expires_in)) {
      let value ={
        message: 'Token is going to be expired in ' + (this.difference/60).toFixed() +'mins. Please save your changes!!',
        success: false
      }
      this.openDialogBox(value);
    } 
  }
  }, 5000);
});
    // console.log("Running in", environment.baseUrl,environment.clientID)
  }

  private tokenExpiredAlert(token:string,expiryTime:string){
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    let currentTime = Math.floor((new Date).getTime() / 1000);
    this.difference = expiry - currentTime;
    let percentageOfCurrentTime = ((this.difference/parseInt(expiryTime)) * 100);
    return parseInt(percentageOfCurrentTime.toFixed()) <= 15;
  }

  openDialogBox(value:any) {
    if(value){
    this.headerService.setSessionInfo(true);
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      data: { type: 'logout',value:value },
      height: 'auto',
      width: '800px',
      disableClose: true
    });
    if (this.id) {
      clearInterval(this.id);
    }
  }
}
}
