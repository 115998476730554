import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
/**Component which holds SpinnerService */
@Injectable({
  providedIn: 'root'
})
/**Component which holds SpinnerService */
export class SpinnerService {
  /**Variable which holds spinnerSubject */
  private spinnerSubject = new Subject<any>();
  /**Variable which holds spinnerObservable */
  spinnerObservable = this.spinnerSubject.asObservable();
   /** Function to handle showOrhideSpinner */
  showOrhideSpinner(condition:boolean) {
    this.spinnerSubject.next(condition);
  }
}
