/** Class which holds WebScrapperRequest */
export class WebScrapperRequest {
}
/** Interface which holds WebScrapperRequest */
export interface WebScrapperRequest {
    /** Varaible which holds the contentType data */
    contentType: string[];
     /** Varaible which holds the country data */
    country: string;
     /** Varaible which holds the crop data */
    crop: string;
     /** Varaible which holds the cropstage data */
    cropstage: string[];
     /** Varaible which holds the parentId data */
    parentId: number;
     /** Varaible which holds the userId data */
    userId: number;
}