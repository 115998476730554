import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SuccessErrorPopupComponent } from '../content-curator/components/common/success-error-popup/success-error-popup.component';
import { SessionStorageService } from 'angular-web-storage';
import { SpinnerService } from '../content-creator/services/spinner/spinner.service';
import { LoginService } from '../content-creator/services/login/login.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../content-creator/components/common/snack-bar/snack-bar.component';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    public dialog: MatDialog,
    public sessionStorageService: SessionStorageService,
    public spinnerService: SpinnerService,
    public loginServiceProvider: LoginService,
    public router : Router,
    public _snackBar: MatSnackBar
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has('Anonymous')) {
      const headers = request.headers.delete('Anonymous');
      return next.handle(request.clone({ headers }));
    }
    let sessionData: any = this.sessionStorageService.get('bearerToken');
    let UserLoginTime: any = this.sessionStorageService.get('UserLoginTime');
    let userActiveTime: any;

    if (UserLoginTime) {
      userActiveTime = (new Date().getTime() - new Date(UserLoginTime).getTime()) / 1000;
    }

    if ((userActiveTime && userActiveTime >= sessionData.expires_in - 20) || 
    (userActiveTime && userActiveTime > sessionData.expires_in)) {
      this._snackBar.openFromComponent(SnackBarComponent, {
        data: { type: 'error', message: 'Login Expired!!!' },
        duration: 5000,
      });
      this.dialog.closeAll()
      this.router.navigateByUrl('/login');
    }

    let accessToken = sessionData?.access_token ? sessionData?.access_token : '';

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ` + accessToken,
        'Cache-Control' :'no-cache'
      },
    });

    return next.handle(request).pipe(timeout(300000),
      catchError((error: HttpErrorResponse) => {
        let errorObj = {
          message: 'Error: We are unable to process the request. Please try again later',
          success: false,
        };
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorObj.message = `Error: ${error.error.message}`;
        } 
        else if(error instanceof TimeoutError){
          errorObj.message = `Error: We are unable to process the request. Please try again later`
        }
        else if (error.error.message) {
          // server-side error
          errorObj.message = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorObj.message = `Error: We are unable to process the request. Please try again later`;
        }
        this.spinnerService.showOrhideSpinner(false);
        this.dialog.open(SuccessErrorPopupComponent, { data: errorObj });
        return throwError(errorObj);
      })
    );
  }
}
