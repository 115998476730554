/** Class which holds SwitchRoleRequest */
export class SwitchRoleRequest {

}
/** Interface which holds SwitchRoleRequest */
export interface SwitchRoleRequest {
    /** Varaible which holds the id data */
    newRole: string;
    /** Varaible which holds the token data */
    // token: string;
}