import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../content-creator/services/spinner/spinner.service';
/**Component which holds Dialog box functionality*/
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
/**Class which holds Dialog box functionality*/
export class SpinnerComponent implements OnInit {
  /**Constructor which holds services
   * @param {SpinnerService}
  */
  constructor(public spinnerService : SpinnerService) { }
  /**Varaible which holds boolean value of showSpinner */
  showSpinner:boolean = false;
  /**ngOnint lifecycle hook */
  ngOnInit(): void {
    this.spinnerService.spinnerObservable.subscribe((data) => {    
      this.showSpinner = data;
     });
  }


}
