/** Class which holds ExternalLoginRequest */
export class ExternalLoginRequest {

}
/** Interface which holds ExternalLoginRequest */
export interface ExternalLoginRequest {
    /** Varaible which holds the userName data */
    userName: string;
    /** Varaible which holds the password data */
    password: string;
}