import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalValuesService } from '../global-values/global-values.service';
import { SessionStorageService } from 'angular-web-storage';
import { ContentListResponse } from '../../models/menu/content-list/content-list.response';
import { ContentHierarchyResponse } from '../../models/menu/content-hierarchy/content-hierarchy.response';
import { CreateVariantResponse } from '../../models/menu/variant/create-variant/create-variant.response';
import { CreateVariantRequest } from '../../models/menu/variant/create-variant/create-variant.request';
import { VariantListResponse } from '../../models/menu/variant/variant-list/variant-list.response';
import { GetHierarchyResponse } from '../../models/menu/get-hierarchy/get-hierarchy.response';
@Injectable({
  providedIn: 'root',
})
/**Class which holds MenuService functionality */
export class MenuService {
  /**
   * Constructor which holds httpclient and service
   * @param http HTTPClient imports
   * @param {GlobalValuesService}
   * @param {SessionStorageService}
   */
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}
  private renameAutoSaveEnabledSubject = new Subject<any>();
  private renameUpdateAutoSaveEnabledSubject = new Subject<any>();
  
  /**Variable which holds menuContentUrl */
  menuContentUrl: string = this.globalValue.creatorBaseURL + 'content/hierarchy?className=';
  /**Function to handle menuContent
   * @param menu
   */
  menuContentHierarchy(menu: string): Observable<ContentHierarchyResponse> {
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({
      token: sessionData.token,
    });
    return this.http.get<ContentHierarchyResponse>(this.menuContentUrl + menu, {
      headers,
    });
  }
  /**Variable which holds menuContentListUrl */
  menuContentListUrl: string = this.globalValue.creatorBaseURL + 'content/list';
  /**Function to handle menuContentList
   * @param params
   */
  menuContentList(params: any): Observable<ContentListResponse> {
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({
      token: sessionData.token,
    });
    return this.http.get<ContentListResponse>(this.menuContentListUrl, {
      headers: headers,
      params: params,
    });
  }

  /**Variable which holds menuContentListUrl */
  menuCreateVariantUrl: string =
    this.globalValue.creatorBaseURL + 'variant/create';
  /**Function to handle menuContentList
   * @param params
   */
  menuCreateVariant(
    data: CreateVariantRequest
  ): Observable<CreateVariantResponse> {
    // console.log(data);
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({
      token: sessionData.token,
    });
    return this.http.post<CreateVariantResponse>(
      this.menuCreateVariantUrl,
      data,
      {
        headers: headers,
      }
    );
  }
  /**Variable which holds menuContentListUrl */
  menuVariantListUrl: string =
    this.globalValue.creatorBaseURL + 'variant/list?parentId=';
  /**Function to handle menuContentList
   * @param params
   */
  menuVariantList(id: number): Observable<VariantListResponse> {
    let sessionData = this.sessionStorageService.get('userToken');
    var headers = new HttpHeaders({
      token: sessionData.token,
    });
    return this.http.get<VariantListResponse>(this.menuVariantListUrl + id, {
      headers: headers,
    });
  }

  /**Variable which holds menuGetHierarchyUrl */
  menuGetHierarchyUrl: string = this.globalValue.creatorBaseURL + 'get-hierarchy';
  /**Function to handle menuContentList */
  menuGetHierarchy(): Observable<GetHierarchyResponse> {
    return this.http.get<GetHierarchyResponse>(this.menuGetHierarchyUrl);
  }

  /**Function to handle setMenuItemInfo */
  setRenameAutoSaveEnabledSubject(val: any): void {
    this.renameAutoSaveEnabledSubject.next(val);
  }
  /**Function to handle getMenuItemInfo */
  getRenameAutoSaveEnabledSubject(): Observable<any> {
    return this.renameAutoSaveEnabledSubject.asObservable();
  }
  /**Function to handle setMenuItemInfo */
  setRenameUpdateSaveEnabledSubject(val: any): void {
    this.renameUpdateAutoSaveEnabledSubject.next(val);
  }
  /**Function to handle getMenuItemInfo */
  getRenameUpdateSaveEnabledSubject(): Observable<any> {
    return this.renameUpdateAutoSaveEnabledSubject.asObservable();
  }
}
